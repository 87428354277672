.btn {
    margin-top: 2rem;
}

.icon-size {
    height: 50px;
    margin-bottom: 2rem;
}

#font {
    color: #31855B;
}

.chx-green {
    fill: #31855B
}