.center{
    text-align: center;
}
.floatRight{
    float: right;
}
.pagination{
    width: 100%;
}

#approved-speciality-list-table {
    table-layout: fixed;
tbody th:first-child {
        padding-left: 0;
    }

    td,th {
        text-align: left !important;
        // padding-right: 3.5rem;
    }

    tbody th,
    td {
        padding: 14px;
    }
}
.lds-pagination-list {
    float: right;
}