.section {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.blue-btn {
  background-color: #202d50 !important;
  color: #ffffff !important;
  border-color: #202d50 !important;
}

.red-btn {
  background-color: #d52b1e !important;
  color: #ffffff !important;
  border-color: #d52b1e !important;
}

.light-green-btn {
  background-color: #45ba7f !important;
  color: #ffffff !important;
  border-color: #45ba7f !important;
}

.green-btn {
  background-color: #31855b !important;
  color: #ffffff !important;
  border-color: #31855b !important;
}

.dept-badges {
  background-color: #00749e !important;
  color: #ffffff !important;
  border-color: #00749e !important;
}

.draft-badge {
  background-color: #032fa7 !important;
  border-color: #032fa7 !important;
}

.blue-bdr-btn {
  color: #202d50 !important;
  border-color: #202d50 !important;
  background-color: #ffffff !important;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

#main {
  background-color: #f5f5f5;
}

.ml-4 {
  margin-left: 1.5rem !important;
}
.mr-4 {
  margin-right: 1.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.px-10 {
  padding: 0 10rem !important;
}

.nav-link {
  color: black;
  text-decoration: none;
}

.nav-link:hover {
  color: var(--lds-color-action-hover);
  text-decoration: underline;
}

a.active {
  color: var(--lds-color-action-active);
  text-decoration: underline;
}

.lds-side-nav-scroll-container {
  overflow-y: hidden !important;
}
.floatRight {
  float: right;
}
/* .lds-side-nav-menu-container {
  margin-top: 50px;
}

.content {
  margin-left: 320px;
}

@media screen and (max-width: 991px) {
  .lds-side-nav {
    width: 100% !important;
    height: auto !important;
    position: relative !important;
  }
  div.content {margin-left: 0;}
}

.sidebar{
 top: 50px;
  position: sticky !important;
} */
