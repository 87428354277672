.specialties-card {
  .card-body {
    padding: 1.5rem;
  }
}

#specialities-table {
  th:last-child {
    width: 15%;
  }

  th,
  td {
    text-align: left;
    padding: 0px;
  }
  th button {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

#speciality-list-table {
  th:last-child {
    cursor: auto;
    .lds-button-base {
      cursor: auto;
    }
    .indicator.none {
      display: none !important;
    }
  }
  .delete-sp span {
    cursor: pointer;
  }
  th:first-child > button,
  th:first-child {
    padding-left: 0 !important;
  }
  th {
    text-align: left;
    font-weight: bold;
  }
  td {
    text-align: right;
  }
  tbody th,
  td {
    padding: 14px;
  }
}

.savecl {
  text-align: right;
}

.floatRight {
  float: right;
}
