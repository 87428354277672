.overview-page {
  h3 {
    font-weight: 400;
  }

  .create-list {
    text-align: right;
  }

  .lds-card .card-body {
    padding: 1.5rem;
  }

  .mtop-3 {
    margin-top: 3rem;
  }

  .mtop {
    margin-top: var(--lds-spacing-h3-margin-bottom-desktop);
  }

  .filter-container {
    padding: 0.5rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filter {
    cursor: pointer;
    user-select: none;
    width: 15ch;
    text-align: center;
  }

  .export-button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
