.list-blk {
    .list-content {
        padding-top: 1rem;

        .created-txt {
            color: #757575;
            font-size: 10pt;
            margin-bottom: 8px;
        }

        .del-link {
            margin-top: -1rem;
        }

        .item-title {
            font-weight: 500;
        }

        .grey-clr {
            background-color: #616161;
        }
    }
}