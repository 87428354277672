.headingMargin {
  color: #212121 !important;
  text-decoration: none !important;
}

.header-height {
  margin-left: 10px;
}

.up-caret,
.down-caret {
  width: 0px;
  height: 0px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.up-caret {
  border-bottom: 12px solid #383434;
  margin-bottom: 5px;
}

.down-caret {
  border-top: 12px solid #383434;
  margin-top: 5px;
}

.opacity-button {
  opacity: 1;
}
.no-opacity-button {
  opacity: 0.3;
}

th {
  font-weight: bold !important;
}
