.details-page {
  h3 {
    font-weight: 400;
  }

  .create-list {
    text-align: right;
  }

  .lds-card .card-body {
    padding: 1.5rem;
  }

  .mtop-3 {
    margin-top: 3rem;
  }

  .mtop {
    margin-top: var(--lds-spacing-h3-margin-bottom-desktop);
  }
}
.create-specialty {
  text-align: right;
}
.details-card {
  .card-body {
    padding: 1.5rem;
  }
}
.created-txt {
  color: #757575;
  font-size: 10pt;
  margin-bottom: 8px;
}
.details-heading {
  .speciality-title {
    align-items: center !important;
    h3 {
      font-weight: 500 !important;
    }
  }
}
.edit-title-lnk {
  font-size: small !important;
}
.grey-clr {
  background-color: #616161 !important;
}
.savecl {
  float: right !important;
}
.mrgn-left {
  margin-left: 1rem !important;
}

.mrgn-tp-6 {
  margin-top: 1.5rem !important;
}
h4.brand-indictn-hdng {
  // margin-top: 1rem !important;
  font-weight: 400 !important;
}
.next-btn-blk {
  justify-content: flex-end !important;
  // text-align: right !important;
  // margin-top: 5rem !important;
  align-items: center;
}
.next-btn-mole {
  justify-content: flex-end !important;
  // text-align: right !important;
  // padding-top: 0rem;
  align-items: center;
}

#margin-btn {
  margin-bottom: 5rem !important;
}
.mrgn-rt {
  margin-right: 1rem !important;
}
.margin-checbox {
  margin-top: 1.5rem !important;
}
.AddRespBtn {
  padding-top: 1.5rem !important;
  float: right !important;
}
.overlap {
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
}
.disabled-link {
  // color: currentColor;
  // cursor: not-allowed;
  // opacity: 0.5;
  visibility: hidden;
}
.delete-sp span {
  cursor: pointer;
}
.margin-saved {
  float: right;
  margin-top: 31px;
  font-style: italic;
  margin-right: 20px;
  color: #757575;
  font-size: 10pt;
}
