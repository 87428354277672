.approved-page {
  margin-left: 4rem;
}
.filter {
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
  padding-right: 1rem;
}
.section {
  overflow: hidden;
  // height: 100vh;
}
.apprSpecTable {
  margin-top: 3rem;
  margin-right: 1rem;
}
