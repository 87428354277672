.ua-align {
    margin: auto;
    width: 60%;
    padding: 10px;
    text-align: center;
}

.ua-icon-checkmark-filled {
    fill: #d52b1e;
    height: 80px;
}

.ua-margin-top {
    margin-top: 60px;
    margin-bottom: 20px;
    height: 120px;
}

.ua-margin-bottom {
    margin-bottom: 10rem;
    background-color: white;
}