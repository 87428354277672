.bu-btn {
    float: right;
}

.bu-list-table th{
    font-weight: bold;
}

.edit-btn{
    margin-left: 8rem;
}

.delete-btn {
    margin-right: 8rem;
}

.edit-btn, .delete-btn {
    cursor: pointer;
}

.floatRight {
    float: right;
}

.mrgn-rt {
    margin-right: 1rem;
}

.cancelbtn {
    margin-right: 1rem;
}

