.users-card {
  .card-body {
    padding: 1.5rem !important;
  }
}
.user-list-table {
  table-layout: fixed;
  tr > td:first-child {
    padding: 1.2rem !important;
    padding-left: 2.5rem !important;
  }
  th:last-child {
    pointer-events: none;
    .indicator.none {
      display: none;
    }
  }
  th,
  td {
    text-align: center !important;
    word-wrap: break-word;
    vertical-align: middle;
  }

  th:first-child {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 12ch;
  }

  td {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;
  }
}

.floatRight {
  float: right;
}

.mrgn-rt {
  margin-right: 1rem;
}

.savecl {
  float: right;
}
.i-action {
  // padding: 0px !important;
  svg {
    margin: 0px 2px;
    fill: #212121;
    cursor: pointer;
  }
}
.overlap {
  position: absolute;
  z-index: 10;
  background-color: #ffffff;
}
.right {
  float: right;
}
