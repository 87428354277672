.floatRight {
  float: right;
}
.test {
  margin-right: 20rem;
}
.buttonMargin {
  margin-left: 1rem;
}

#specialities-table {
  white-space: nowrap;
  th,
  td {
    text-align: left !important;
    padding: 2rem !important;
  }
}
