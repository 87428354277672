.icon-size{
    display: flex;
    justify-content: center;
}
.msg-data{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}
.mrgn{
    margin-top: 2.5rem;
    margin-left: 12rem;
}
.Updated {
    background-color: #B6E3FF;
}

.Created {
    background-color: #9be9a8;
}

.Deleted {
    background-color: #FF818286;
}