.created-txt {
    color: #757575;
    font-size: 10pt;
    margin-bottom: 8px;
}

.px-10 {
    padding: 0 10rem;
}

.savecl {
    float: right;
}

.item-title {
    color: #757575;
    font-weight: 500;
}

pre{
    white-space: pre-wrap;
    word-wrap: break-word;
}

.ql-editor {
    min-height: 200px;
    font-size: 16px;
}

.mention {
    /* color : blue !important; */
    /* box-shadow: 0 0 0 1px var(--ds-border-selected, #0065FF); */
    border-color: transparent;
    background-color: var(--ds-background-selected, #DEEBFF);
    color: var(--ds-text-subtle, #42526E);
}



#quill-mention-list {

    color: black;
    font-weight: 600;
    list-style-type: none;
    background-color: #E0E0E0;
    padding: 5px 5px 5px 5px;
    border-radius: 5px;
}

#quill-mention-list>li:hover {
    cursor: pointer;
    background-color: #FAFAFA;
}

#quill-mention-list>li {
    padding: 2px 2px 2px 2px;
    border-radius: 5px;
}

.ql-mention-list-item.selected {
    background-color: #FAFAFA;
}

button.lds-button.compact{
    background-color: #00749E;
}